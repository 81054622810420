import "./textarea.scss"

import React from "react"

const Textarea = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  required,
  rows,
}) => {
  return (
    <div className="textarea">
      <textarea
        className="textarea__textarea"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        rows={rows}
      ></textarea>
    </div>
  )
}

export default Textarea
