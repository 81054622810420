import "./contact-form.scss"

import React, { useState } from "react"
import axios from "axios"
import { toast } from "react-toastify"

import Input from "components/Input/Input"
import Select from "components/Select/Select"
import Textarea from "components/Textarea/Textarea"
import Checkbox from "components/Checkbox/Checkbox"
import Button from "components/Button/Button"
import { navigate } from "gatsby"

const ContactForm = () => {
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [phone, setPhone] = useState("")
  const [legal, setLegal] = useState(false)

  const resetForm = () => {
    setSend(false)
    setName("")
    setEmail("")
    setPhone("")
    setSubject("")
    setMessage("")
    setLegal(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("subject", subject)
    formData.set("message", message)
    formData.set("legal", legal)

    axios({
      method: "post",
      url: "https://woo.memtech.pl/wp-json/contact-form-7/v1/contact-forms/8/feedback/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        toast.success("Wiadomość została wysłana.")
        resetForm()
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(() => {
        toast.error("Wystąpił błąd przy wysyłaniu wiadomości.")
      })
  }

  return (
    <form className="contact-form" onSubmit={formSubmit}>
      <Input
        placeholder="Imię"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div className="row">
        <div className="col-sm-6">
          <Input
            placeholder="E-mail"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Telefon"
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
      </div>
      <Select
        placeholder="Temat"
        id="subject"
        value={subject}
        onChange={e => setSubject(e.target.value)}
        options={[
          { label: "Zapytanie o produkt", value: "Zapytanie o produkt" },
          { label: "Zwrot lub reklamacja", value: "Zwrot lub reklamacja" },
          { label: "Status zamówienia", value: "Status zamówienia" },
          { label: "Serwis", value: "Serwis" },
          { label: "Współpraca B2B", value: "Współpraca B2B" },
          { label: "Inny", value: "Inny" },
        ]}
      />
      <Textarea
        placeholder="Wiadomość"
        id="message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="6"
      />
      <div className="contact-form__cta">
        <div className="row">
          <div className="col-md-6">
            <Checkbox
              name="legal"
              checked={legal}
              onChange={() => setLegal(!legal)}
              required
            >
              Chcę zadać pytanie dotyczące oferty firmy MEMTECH Sp. z o.o. Sp.
              k. i akceptuję{" "}
              <a href="/polityka-prywatnosci/" target="_blank">
                politykę prywatności
              </a>
              .
            </Checkbox>
          </div>
          <div className="col-md-6 text-right">
            <Button type="submit" disabled={!legal}>
              {send === true ? "Wysyłanie..." : "Wyślij"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
